import React from 'react'

export default function Stock() {
    return (
        <div className='text-center'>
            <h1 className='logo-1'>
                Stock Diagnostic
            </h1>

            <h3 className='logo-1'>
                Dig Deep
            </h3>

            <div className='black_search_section mt-5 d-flex align-items-center justify-content-center'>
                <input type="text" placeholder='Enter Stock name/code' />
                <button className='black_check_box glowing-btn w-25'>
                    <span class='glowing-txt'>Ch<span class='faulty-letter'>eck</span>  Now</span>
                </button>
            </div>

            <div className='free_stock mt-5'>
                <img src={require('../../Assets/black_free.png')} />
            </div>


            <div className='report_box '>
                <h3>
                    View Stock Mar 22 report
                </h3>
            </div>

            <p className='forcast_result'>
                The forecast results are for reference only, please refer to the actual opening
            </p>

            <div className='hr_line'></div>

            <div className='mt-5'>
                <h3 className='black_six_head'>
                    Comprehensive Diagnostic in six dimensions
                </h3>
            </div>

            <div className='text-center'>
                <img src={require('../../Assets/Upside__1_-removebg-preview.png')} className='six_features' />
            </div>
        </div>
    )
}
