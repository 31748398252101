import logo from './logo.svg';
import './App.css';
import Stockhead from './Components/Stockhead';
import DiagnosticReport from './Components/DiagnosticReport';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Blue from './Components/Blue/Blue';
import Green from './Components/Green/Green';
import Black from './Components/Black/Black';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Blue/>}/>
          <Route path='/Green' element={<Green/>}/>
          <Route path="/Black" element={<Black/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
