import React from 'react'
import Stock from './Stock'
import GreenDiagnostic from './GreenDiagnostic'

export default function Green() {
  return (
    <div className='Green_stock'>
        <Stock/>
        <GreenDiagnostic/>
    </div>
  )
}
