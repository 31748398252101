import React from 'react'

export default function GreenDiagnostic() {
    return (
        <div className='text-center d-flex flex-column justify-content-center align-items-center'>
            <div className='green_diagnostic_report_head'>
                <h3>
                    Stock Diagnostic report
                </h3>
            </div>

            <div>
                <div className='diagnostic_list_section'>
                    <ul className='diagnostic_list list animate'>
                        <li className='item item1'>
                            50****There is an opportunity to earn exchange rate difference, and you can look forward to the yield next year
                        </li>

                        <li className='item item1'>
                            52****Break through the triangle convergence, you can enter the market on the right side
                        </li>

                        <li className='item item1'>
                            53****The stock price is low, you can enter the market in batches according to the layout
                        </li>

                        <li className='item item1'>
                            54****The legal person continues to oversell and resumes bearish operations
                        </li>
                    </ul>

                    <div>
                        <button className='green_report_btn'>
                            Get Report
                        </button>
                    </div>
                </div>

                <div className='mt-5 mb-5'>
                    <h1>
                        your stocks rising or falling ?
                    </h1>

                    <div className='search_section mt-5'>
                        <input type="text" placeholder='Enter Stock name/code' />
                    </div>

                    <button className='green_check_btn'>
                        Check Now
                    </button>
                </div>
            </div>

            <p className='note_design'>
                Investment is risky, the choice needs to be cautious

                To engage in securities investment transactions, please conduct through legal securities operating agencies.
            </p>
        </div>
    )
}
