import React from 'react'
import Stock from './Stock'
import BlackDiagnostic from './BlackDiagnostic'

export default function Black() {
  return (
    <div className='black_stock'>
       <Stock/>
       <BlackDiagnostic/> 
    </div>
  )
}
