import React from 'react'
import Stockhead from '../Stockhead'
import DiagnosticReport from '../DiagnosticReport'

export default function Blue() {
  return (
    <div className='stock_head'>
        <Stockhead/>
        <DiagnosticReport/>
    </div>
  )
}
