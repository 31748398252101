import React from 'react'

export default function Stock() {
    return (
        <div>
            <h1>
                Stock Diagnostic
            </h1>

            <h3>
                Dig Deep
            </h3>

            <div className='green_search_section'>
                <input type="text" placeholder='Enter Stock name/code' />
                <button className='green_check_box'>
                    Check Now
                </button>
            </div>

            <div className='free_stock mt-5'>
                <img src={require('../../Assets/green_free.png')} />
            </div>


            <div className='report_box '>
                <h3>
                    View Stock Mar 22 report
                </h3>
            </div>

            <p className='forcast_result'>
                The forecast results are for reference only, please refer to the actual opening
            </p>

            <div className='hr_line'></div>

            <div className='mt-5'>
                <h3>
                    Comprehensive Diagnostic in six dimensions
                </h3>
            </div>

            <div className='text-center'>
                <img src={require('../../Assets/Upside__1_-removebg-preview.png')} className='six_features' />
            </div>
        </div>
    )
}
